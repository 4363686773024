import React, { useEffect, useState } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { Row, Col, Spinner } from "react-bootstrap";
import SerumTooltip from "../Tooltip";
import { SerumStatus } from "../../Enums";
import { Avatar } from "@mui/material";
import BuyModal from "../buyModal";
import ConfirmTransform from "../confirmTransform";
import { ethers } from "ethers";
import { SerumClassify } from "../../Enums";
import {
  SERUM_CONTRACT,
  RINKEBY_SERUM_CONTRACT,
} from "../../connectors/constants";
const SerumCard = ({
  user,
  contract,
  transormParent,
  data,
  nftData = "",
  isTchamber = false,
  serumsBuyFinished,
  selectedChainId,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState();
  const [showTransformModal, setShowTransformModal] = useState();

  const onImageLoaded = () => {
    setLoaded(true);
  };

  const handleBuy = async () => {
    console.log("Buyyy");
    const userItem = await contract.MintUser(user, data.id, {
      value: ethers.utils.parseEther(data.price.toString()),
    });
    if (userItem) {
      console.log("user", user, "token", userItem);
      //serumsBuyFinished();
    }
  };

  const handleTransform = async () => {
    console.log("Transform");
    const id = await transormParent(nftData, data);
  };

  return (
    <div className="position-relative pb-5 h-100">
      <Flippy
        flipOnHover={true} // default false
        flipOnClick={false} // default false
        flipDirection="horizontal" // horizontal or vertical
        className="flip-main h-100"
      >
        <FrontSide className="flip-front">
          <Avatar
            src={data.img}
            className="img img-fluid border-radius-0 card-img"
            alt="serum"
            loading="lazy"
            onLoad={onImageLoaded}
          />
          {!loaded && (
            <div className="image-container-overlay">
              <Spinner animation="border" variant="warning" />
            </div>
          )}
          <Row className="mb-4">
            <Col>
              <div className="flip-front-footer">{data.title}</div>
            </Col>
          </Row>
          <Row className="flip-front-price">
            <Col>
              <div className="flip-front-footer text-end">
                {data.price === 0 ? "Free" : data.price+`${[80001, 137].includes(selectedChainId) ? ' MATIC' : ' ETH'}`}
              </div>
            </Col>
          </Row>
        </FrontSide>
        <BackSide className="flip-back text-left flippy-icons">
          <label>
            <b>Drop:</b> {data.category}
          </label>
          <label>
            <b>Category: </b>
            {data.classification === SerumClassify.Generic
              ? "General"
              : data.classification === SerumClassify.PerCollection
              ? "Per Collection"
              : "Per Card"}
          </label>

          <label>
            <b>Description:</b> {data.description}
          </label>
          <Row className="mt-5 flippy-buttons">
            <Col className="text-center">
              {data.isOwnedByUser ? (
                data.status === SerumStatus.Full ? (
                  <SerumTooltip title="Full Serum">
                    <img
                      src={process.env.PUBLIC_URL + "images/icons/full.png"}
                      alt="serum"
                    />
                  </SerumTooltip>
                ) : (
                  data.status === SerumStatus.Empty && (
                    <SerumTooltip title="Empty Serum">
                      <img
                        src={process.env.PUBLIC_URL + "images/icons/empty.png"}
                        alt="serum"
                      />
                    </SerumTooltip>
                  )
                )
              ) : (
                <>
                  <SerumTooltip
                    title={
                      data.price === 0
                        ? "Get this Serum for FREE"
                        : "Buy this Serum"
                    }
                  >
                    <img
                      src={
                        data.price === 0
                          ? process.env.PUBLIC_URL + "images/icons/forFree.png"
                          : process.env.PUBLIC_URL + "images/icons/buy.png"
                      }
                      alt="serum"
                      className="cursor-pointer width-30"
                      onClick={() => {
                        setShowBuyModal(true);
                      }}
                    />
                  </SerumTooltip>
                  {showBuyModal && (
                    <BuyModal
                      show={showBuyModal}
                      handleCloseParent={() => {
                        setShowBuyModal(false);
                      }}
                      data={data}
                      handleBuyParent={async () => {
                        await handleBuy();
                      }}
                    />
                  )}
                </>
              )}
            </Col>
            <Col>
              <SerumTooltip title="Serum Link in OpenSea">
                <a
                  href={`${
                    selectedChainId === 80001
                      ? `${process.env.REACT_APP_OPENSEA_LINK}mumbai/${SERUM_CONTRACT}/${data.id}`
                      : selectedChainId === 137
                      ? `${process.env.REACT_APP_OPENSEA_LINK}matic/${process.env.REACT_APP_POLYGON_SERUM_CONTRACT}/${data.id}`
                      : `${process.env.REACT_APP_OPENSEA_LINK}${RINKEBY_SERUM_CONTRACT}/${data.id}`
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer"
                >
                  <img
                    src={process.env.PUBLIC_URL + "images/icons/opensea.png"}
                    alt="serum"
                    className="width-30 cursor-pointer"
                  />
                </a>
              </SerumTooltip>
            </Col>
          </Row>
        </BackSide>
      </Flippy>
      <div className="position-absolute transform-icon">
        {data.isOwnedByUser && isTchamber && (
          <>
            <SerumTooltip title="Transform Now">
              <img
                className="cursor-pointer"
                width="30px"
                src={process.env.PUBLIC_URL + "images/icons/transform.png"}
                onClick={() => {
                  setShowTransformModal(true);
                }}
              />
            </SerumTooltip>
            {showTransformModal && (
              <ConfirmTransform
                show={showTransformModal}
                handleCloseParent={() => {
                  setShowTransformModal(false);
                }}
                nftData={nftData}
                serumData={data}
                handleTransformParent={async () => {
                  await handleTransform();
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SerumCard;
